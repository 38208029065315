import createStatusObject from '../../helpers/create-status-object';

export default function request(
  state: any,
  { namespace, request, method, status }: any
) {
  const response = {
    [method]: {
      request,
      status: createStatusObject(status),
      error: null
      //result: null
    }
  };
  return namespace
    ? state.merge({ namespaces: { [namespace]: response } }, { deep: true })
    : state.merge(response, { deep: true });
}

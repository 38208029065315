import React from 'react';
import { RouteConfig } from 'react-router-config';
import { useHistory } from 'react-router-dom';

const Proposal = React.lazy(()=> import('../pages/Proposal'));
const Consumption = React.lazy(()=> import('../pages/Proposal/Consumption'));
const Customer = React.lazy(()=> import('../pages/Proposal/Customer'));
const Presentation = React.lazy(()=> import('../pages/Proposal/Presentation'));
const Agreement = React.lazy(()=> import('../pages/Proposal/Agreement'));
const AgreementSuccess = React.lazy(()=> import('../pages/Proposal/Agreement/Success'));

export const getProposalId = () => {
  const proposalId = localStorage['proposal-id'];
  return proposalId;
}

function Redirect() {
  const history = useHistory();
  const id = getProposalId();
  const redirectRouteName = id ? `/${id}` : '/dados-do-cliente';
  history.replace(redirectRouteName);
  return null;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: Redirect
  },
  {
    path: '/:step(dados-do-cliente)',
    component: Proposal,
    routes: [
      {
        path:'/dados-do-cliente',
        component: Customer
      }
    ]
  },
  {
    path: '/:id?/:step?',
    component: Proposal,
    routes: [
      {
        path:'/:id?/dados-do-cliente',
        component: Customer
      },
      {
        path:'/:id?/dados-de-consumo',
        component: Consumption
      },
      {
        path: '/:id?/resultado',
        component: Presentation
      },
      {
        path: '/:id?/contratar',
        component: Agreement
      },
      {
        path: '/:id?/sucesso',
        component: AgreementSuccess
      }
    ]
  }
];

export default routes;

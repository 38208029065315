export default function store(
  state: any,
  { records, publications, last, connected }: any
) {
  return state
    .set('store', records)
    .set('publications', publications)
    .setIn(['meta', 'last'], last)
    .setIn(['meta', 'connected'], connected);
}

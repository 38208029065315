import createModel from './model';
import createAuthModel from './authentication';
import { Application } from '@feathersjs/feathers';
import { Model } from '@rematch/core';

type Clients =
  | { restClient: Application; socketClient?: Application }
  | { restClient?: Application; socketClient: Application };

type Transport = 'rest' | 'socket';

interface ServiceDefinition {
  name: string;
  path: string;
}

export type InitProps = {
  transport: Transport;
  services: ServiceDefinition[];
  authentication: {
    transport: Transport;
  };
} & Clients;

interface Models {
  [modelName: string]: Model;
}

export default function init({
  restClient,
  socketClient,
  transport,
  services,
  authentication
}: InitProps) {
  const models: Models = services.reduce((obj, service) => {
    const model = createModel({
      modelName: service.name,
      rest: restClient?.service(service.path),
      socket: socketClient?.service(service.path),
      transport
    });

    return { ...obj, [service.name]: model };
  }, {});

  /**
  |--------------------------------------------------
  | Authentication
  |--------------------------------------------------
  */

  if (authentication) {
    models.authentication = createAuthModel({
      socket: socketClient,
      rest: restClient,
      transport: authentication.transport
    });
  }

  return models;
}

import { init, RematchRootState } from '@rematch/core';
import { models as feathersModels } from '../feathers';
import alerts from './models/alerts';

const models = { ...feathersModels, alerts };

const store = init({
  redux: {
    devtoolOptions: {}
  },
  models
});

export default store;

export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type iRootState = RematchRootState<typeof models>;

const defaultState = {
  find: {},
  get: {},
  create: {},
  update: {},
  patch: {},
  remove: {},
  publications: {},
  store: [],
  meta: {
    connected: false,
    last: null
  }
};

export default defaultState;
import { Params, Service } from '@feathersjs/feathers';
import interceptors from './interceptors';

interface CreateParams {
  data: any;
  params?: Params;
  namespace?: string;
}

export default function create(
  dispatch: any,
  modelName: string,
  rest: Service<any>
) {
  return async ({ data, params, namespace }: CreateParams, _rootState: any) => {
    const request = { data };
    dispatch[modelName].request({
      method: 'create',
      namespace,
      request,
      status: 'saving'
    });

    try {
      const result = await rest.create(data, params);
      dispatch[modelName].response({ method: 'create', namespace, result });
      return result;
    } catch (error) {
      interceptors(dispatch, error);
      dispatch[modelName].error({ method: 'create', namespace, error });
      return { error };
    }
  };
}

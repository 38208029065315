import { createTheme, createStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const defaultTheme = createTheme({});

const theme = createTheme({
  props: {
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
      InputLabelProps: {
        style: {
          fontSize: "0.875rem",
        },
      },
      InputProps: {
        style: {
          fontSize: "0.875rem",
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#FF5010",
    },
    secondary: {
      main: "#069fac",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },

  overrides: {
    MuiTooltip: {
      tooltip: {
        maxWidth: 500,
        [defaultTheme.breakpoints.down("md")]: {
          maxWidth: 320,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#FFF",
        "@media print": {
          boxShadow: "none !important",
        },
      },
    },
    MuiStepper: {
      root: {
				[defaultTheme.breakpoints.down("sm")]: {
					padding: 0
				}
			},
    },
    MuiStepIcon: createStyles({
      active: {
        "&&": {
          color: "#FF5010",
        },
      },
      completed: {
        "&&": {
          color: "#FF5010",
        },
      },
    }),
    MuiTableContainer: createStyles({
      root: {
        width: "100%",
        overflowX: "auto",
      },
    }),
    MuiButton: createStyles({
      root: {
        "&&": {
          marginTop: defaultTheme.spacing(1),
          marginRight: defaultTheme.spacing(1),
        },
      },
    }),
    MuiCardContent: {
      root: {
        "@media AppBar": {
          padding: "5px !important",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
  },
});

export default theme;

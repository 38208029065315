import { AuthenticationRequest } from '@feathersjs/authentication';
import { Application } from '@feathersjs/feathers';
import { Model } from '@rematch/core';
import Immutable from 'seamless-immutable';

export interface FeathersRematchAuthenticationModelConfig {
  rest?: Application;
  socket?: Application;
  transport: 'socket' | 'rest';
}

const defaultState = {
  error: null,
  loading: null,
  signedIn: false,
  user: null,
  token: null,
  data: null
};

export default ({
  rest,
  socket,
  transport = 'socket'
}: FeathersRematchAuthenticationModelConfig) => {
  const clients = { rest, socket };
  const client = clients[transport];

  const model: Model = {
    state: Immutable(defaultState),
    reducers: {
      finished(state: any, { accessToken, user }: any) {
        return state.merge({
          loading: false,
          signedIn: true,
          token: accessToken,
          user
        });
      },
      loading(state: any) {
        return state.merge({ loading: true, error: null });
      },
      node(state, node) {
        return state.merge({ user: { ...state.user, ...node } });
      },
      logout(state: any) {
        return state.merge({
          signedIn: false,
          user: null,
          token: null,
          data: null
        });
      },
      error(state: any, payload: any) {
        return state.merge({
          loading: false,
          error: payload
        });
      }
    },
    effects: (dispatch: any) => ({
      async authenticate(payload: AuthenticationRequest) {
        dispatch.authentication.loading();
        try {
          if (client) {
            const authData = await client.authenticate(payload);
            dispatch.authentication.finished(authData);
            return authData;
          }
          throw new Error(
            'REST/Socket client not specified for authentication.'
          );
        } catch (error) {
          dispatch.authentication.error(error);
          return { error };
        }
      },
      async logout() {
        await client?.logout();
      }
    })
  };

  return model;
};

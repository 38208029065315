interface IState {
  type: string | null;
  message: string | null;
}

const INITIAL_STATE = {
  type: null,
  message: null
};

const alerts = {
  state: INITIAL_STATE,
  reducers: {
    reset(_state: IState) {
      return { type: null };
    },
    showError(_state: IState, message: string) {
      return { type: 'error', message };
    },
    showSuccess(_state: IState, message: string) {
      return { type: 'success', message };
    }
  }
};

export default alerts;

const config = {
  api: 'https://proposta.terraenergia.com.br/api',
  domain: 'https://proposta.terraenergia.com.br',
  //api: 'http://localhost:3030',
  //domain: 'https://proposta.terraenergia.com.br',
  version: {
    ios: '1.0.0',
    android: '1.0.0'
  }
};

export default config;
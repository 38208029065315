import feathers from '@feathersjs/feathers';
import axios from 'axios';
import rest from '@feathersjs/rest-client';
import authentication from '@feathersjs/authentication-client';

import init from './feathers-rematch';
import config from '../config';

const services = [
  'proposals',
  'agreements',
  'addresses',
  'authManagement'
].map(service =>
  typeof service === 'object' ? service : { name: service, path: `/${service}` }
);

const auth = authentication({
  storageKey: 'feathers-jwt',
  storage: window.localStorage
});

const app = feathers();
const restClient = app
  .configure(rest(config.api).axios(axios))
  .configure(auth);  

const transport = 'rest';

const models = init({
  transport,
  restClient,
  services,
  authentication: {
    transport
  }
});

export { restClient as app, models };

export type Application = typeof restClient;

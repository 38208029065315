import createStatusObject from '../../helpers/create-status-object';

export default function response(
  state: any,
  { namespace, result, method }: any
) {
  const response = {
    [method]: {
      result,
      status: createStatusObject('finished')
    }
  };
  return namespace
    ? state.merge({ namespaces: { [namespace]: response } }, { deep: true })
    : state.merge(response, { deep: true });
}

import response from '../../defaultState';

type StateKeyType = 'find' | 'get' | 'create' | 'update' | 'patch' | 'remove' | 'publications' | 'store' | 'meta'; //prettier-ignore

export default function reset(state: any, stateKey: StateKeyType) {
  if (response?.[stateKey]) {
    return state.merge(
      {
        [stateKey]: response[stateKey]
      },
      { deep: false }
    );
  }
  return state;
}

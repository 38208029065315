import Immutable from 'seamless-immutable';
import reducers from './reducers';
import defaultState from './defaultState';
import { find, get, create, update, patch, remove } from './effects';
import { Service } from '@feathersjs/feathers';
import { Model } from '@rematch/core';

export interface FeathersRematchModelConfig {
  modelName: string;
  rest?: Service<any>;
  socket?: Service<any>;
  transport: 'rest' | 'socket';
}

export default function createModel({
  modelName,
  rest,
  socket,
  transport
}: FeathersRematchModelConfig) {
  const services = { rest, socket };
  const service = services[transport] as Service<any>;

  const model: Model = {
    state: Immutable(defaultState),
    reducers,
    effects: (dispatch: any) => ({
      find: find(dispatch, modelName, service),
      get: get(dispatch, modelName, service),
      create: create(dispatch, modelName, service),
      update: update(dispatch, modelName, service),
      patch: patch(dispatch, modelName, service),
      remove: remove(dispatch, modelName, service)
    })
  };

  return model;
}

import { ThemeProvider, withStyles } from '@material-ui/styles';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from './routes/';
import theme from './theme';

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    'html': {
      '@media print': {
        fontSize: '11.5px'
      }
      
    },
  },
})(() => null);

// …



function App() {

  return (
    <ThemeProvider theme={theme}>
      <GlobalCss />
      <Router>
        <Switch>
          <React.Suspense fallback={null}>
            {renderRoutes(routes)}
          </React.Suspense>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
